import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const InterestingTwitterCampaigns = ({ location }) => {
  const title =
    "【事例付き】企画の参考に！バズった面白いTwitterキャンペーン10選"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【事例付き】企画の参考に！バズった面白いTwitterキャンペーン10選"
          pagedesc="思わず拡散したくなる、ユニークで魅力的なTwitterキャンペーン企画を事例つきでご紹介します。ユーザに楽しんでもらえる面白い企画でフォロワーを獲得し、ファンの増加に繋げましょう。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/interesting-twitter-campaigns/interesting-twitter-campaigns.jpg"
          pagepath="/blog/interesting-twitter-campaigns"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は15分で読むことができます
            </div>
            <h1>
              【事例付き】企画の参考に！バズった面白いTwitterキャンペーン10選
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2022年10月8日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ノートパソコンの画面を一緒に見ている男性と女性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/interesting-twitter-campaigns/interesting-twitter-campaigns.jpg"
                className="w-full"
              />
            </figure>
            <p>
              日々多くの企業がTwitterキャンペーンを開催しています。その中で埋もれずに多くのユーザに目を留めてもらうためには、景品だけでなく企画の内容もとても重要です。
            </p>
            <p>
              Twitterの大きな特徴は拡散性です。面白い内容のキャンペーンはユーザも拡散しやすく、成功すれば大きな成果を得られます。所謂「バズる」というやつですね。ユニークな内容は懸賞用のアカウントではない個人アカウントでもリツイートしやすいので、一般的な内容のキャンペーンよりも幅広い層のユーザに届きやすい特徴があります。
            </p>
            <p>
              この記事では思わず拡散したくなってしまう面白いキャンペーンを、成功事例つきでご紹介します。
            </p>
            <p>この記事はこんな方にオススメです！</p>
            <ul className="blog__border-list">
              <li>面白いTwitterキャンペーンを企画したい</li>
              <li>Twitterキャンペーンの成功事例を知りたい</li>
              <li>企画の内容を一工夫したい</li>
            </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    ゼブラ　フォロー＆RT キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    ファミリーマート フォロー＆RT キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    サントリー、雪印メグミルク Wフォロー＆RT キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    赤城乳業 フォロー＆ハッシュタグ引用リツイート キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    ロッテ 雪見だいふく フォロー＆ハッシュタグツイート
                    キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    大阪王将 フォロー＆ハッシュタグ引用リツイート キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    uno(ウーノ) フォロー＆リツイート キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    湖池屋×モルカー Wフォロー＆リツイート キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id9" className="toc__link">
                    紀文 アース製薬 MUBLiXトリプルフォロー＆リツイート
                    キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    サントリー天然水 フォロー＆リツイート おみくじキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">ゼブラ　フォロー＆RT キャンペーン</h2>
              <p>
                自社製品であるハイマッキーを100本プレゼントという非常にインパクトの強いキャンペーンです。100本！？という驚きと共に思わずリツイートしたくなってしまいますね。そんなにたくさんどうするんだろう…という多くの人が抱くであろう疑問についてのツイートもあり、ユーモア溢れる内容で1.3万リツイートされました。
              </p>
              <p>
                キャンペーンのインパクトとフォロー＆RTという気軽な参加方法がマッチしています。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼使い方は100通り⁈／
                  <br />
                  <br />
                  ゼブラの代表商品『マッキー』はおかげさまで様々な場所・使い方で愛されてきました。
                  <br />
                  そこで、感謝の気持ちを込めて
                  なんとハイマッキー黒100本を5名様にプレゼント🎁‼️
                  <br />
                  いつでもどこでもマッキー🦓✨
                  <br />
                  <br />
                  1.{" "}
                  <a href="https://twitter.com/suraripen?ref_src=twsrc%5Etfw">
                    @suraripen
                  </a>{" "}
                  をフォロー
                  <br />
                  2. この投稿をリツイート
                  <br />
                  <br />
                  9/26 23:59まで！→{" "}
                  <a href="https://t.co/LEoFDBmaIF">
                    pic.twitter.com/LEoFDBmaIF
                  </a>
                </p>
                &mdash; ゼブラ (@suraripen){" "}
                <a href="https://twitter.com/suraripen/status/1572417842611638272?ref_src=twsrc%5Etfw">
                  September 21, 2022
                </a>
              </blockquote>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="ゼブラ公式アカウントのよくいただくご質問ツイート"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/interesting-twitter-campaigns/interesting-twitter-campaigns-2.png"
              />
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/suraripen"
                  target="_blank"
                  rel="noreferrer"
                >
                  ゼブラ公式アカウント
                </a>
                をフォローし、該当ツイートをリツイートします。
              </p>
              <p>当選者にはハイマッキー黒が100本プレゼントされます。</p>
              <p>開催期間は５日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/suraripen"
                  target="_blank"
                  rel="noreferrer"
                >
                  ゼブラ公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 結果は後日DM送付</p>
              <h3>結果通知</h3>
              <p>当選者のみ後日DM送付</p>
            </div>
            <div>
              <h2 id="id2">ファミリーマート フォロー＆RT キャンペーン</h2>
              <p>
                Twitterのサムネイルの表示方法を上手く利用しています。アプリやブラウザから見ると４枚の画像で１枚に見えるようになっています。１枚ずつ個別に画像を見ると製品のPRが表示されます。Twitterキャンペーンにおいてバナーは特に重要な要素です。遊び心のあるバナーがキャンペーンをさらに盛り上げ、魅力的なものにしています。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  🍊🍊🍊━━━━━━━━━━━
                  <br />
                  ファミマ限定
                  <br />
                  -196℃オレンジパンチ
                  <br />
                  発売記念Twitterキャンペーン実施中
                  <br />
                  ━━━━━━━━━━━🍊🍊🍊
                  <br />
                  -196℃オレンジパンチ1ケースが
                  <br />
                  抽選で20名様に当たる🔔
                  <br />
                  <br />
                  🎁応募方法🎁
                  <br />①{" "}
                  <a href="https://twitter.com/famima_now?ref_src=twsrc%5Etfw">
                    @famima_now
                  </a>
                  <br /> をフォロー
                  <br />
                  ②この投稿をリツイート🔃
                  <a href="https://twitter.com/hashtag/%E3%83%95%E3%82%A1%E3%83%9F%E3%83%9E%E3%81%AE%E3%82%AA%E3%83%AC%E3%83%B3%E3%82%B8%E3%83%91%E3%83%B3%E3%83%81?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ファミマのオレンジパンチ
                  </a>{" "}
                  <a href="https://t.co/SQvswGTbbF">
                    pic.twitter.com/SQvswGTbbF
                  </a>
                </p>
                &mdash; ファミリーマート (@famima_now){" "}
                <a href="https://twitter.com/famima_now/status/1574685275891957762?ref_src=twsrc%5Etfw">
                  September 27, 2022
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/famima_now"
                  target="_blank"
                  rel="noreferrer"
                >
                  ファミリーマート公式アカウント
                </a>
                をフォローし、該当ツイートをリツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は７日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/famima_now"
                  target="_blank"
                  rel="noreferrer"
                >
                  ファミリーマート公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
            </div>
            <div>
              <h2 id="id3">
                サントリー、雪印メグミルク Wフォロー＆RT キャンペーン
              </h2>
              <p>
                サントリーと雪印メグミルク、企業同士のコラボ企画です。内臓脂肪対策を売りにしているお互いの商品を相互レビューするという大胆な企画です。星の数も5ではなく4.5、「私は」や「手軽さについては」の部分が強調されるなど、褒め合うだけでなく少し引っかかる表現があることでユーザの興味を引きます。通常Wフォローキャンペーンはユーザの手間が増え、参加率が下がります。
              </p>
              <p>
                まだフォロワーの少ないアカウントでのキャンペーン開催は、単独のフォロー＆リツイートキャンペーンでの実施がオススメです。
              </p>
              <div className="blog__embedded-tweet">
                <blockquote className="twitter-tweet">
                  <p lang="ja" dir="ltr">
                    ／​
                    <a href="https://twitter.com/hashtag/%E3%81%8B%E3%82%89%E3%81%A0%E3%82%92%E6%83%B3%E3%81%86%E3%82%AA%E3%83%BC%E3%83%AB%E3%83%95%E3%83%AA%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                      #からだを想うオールフリー
                    </a>{" "}
                    と
                    <a href="https://twitter.com/hashtag/%E3%82%AC%E3%82%BB%E3%83%AA%E8%8F%8CSP%E6%A0%AA%E3%83%A8%E3%83%BC%E3%82%B0%E3%83%AB%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                      #ガセリ菌SP株ヨーグルト
                    </a>{" "}
                    が<br />
                    抽選で500名様に当たる！🎯
                    <br />＼<br />
                    <br />
                    サントリーと雪印メグミルクさんとのコラボキャンペーン！{" "}
                    <a href="https://twitter.com/hashtag/%E5%86%85%E8%87%93%E8%84%82%E8%82%AA?src=hash&amp;ref_src=twsrc%5Etfw">
                      #内臓脂肪
                    </a>{" "}
                    対策飲料同士、{" "}
                    <a href="https://twitter.com/hashtag/%E7%9B%B8%E4%BA%92%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%97%E3%81%A6%E3%81%BF%E3%81%9F?src=hash&amp;ref_src=twsrc%5Etfw">
                      #相互レビューしてみた
                    </a>
                    ！<br />
                    <br />
                    💡応募方法
                    <br />①
                    <a href="https://twitter.com/suntory?ref_src=twsrc%5Etfw">
                      @suntory
                    </a>
                    と{" "}
                    <a href="https://twitter.com/megmilk_snow?ref_src=twsrc%5Etfw">
                      @megmilk_snow
                    </a>
                    の2つのアカウントをフォロー
                    <br />
                    ②この投稿をRT
                  </p>
                  &mdash; SUNTORY（サントリー） (@suntory){" "}
                  <a href="https://twitter.com/suntory/status/1577086096030064640?ref_src=twsrc%5Etfw">
                    October 4, 2022
                  </a>
                </blockquote>
                <blockquote className="twitter-tweet">
                  <p lang="ja" dir="ltr">
                    ／​
                    <a href="https://twitter.com/hashtag/%E3%82%AC%E3%82%BB%E3%83%AA%E8%8F%8CSP%E6%A0%AA%E3%83%A8%E3%83%BC%E3%82%B0%E3%83%AB%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                      #ガセリ菌SP株ヨーグルト
                    </a>{" "}
                    と
                    <a href="https://twitter.com/hashtag/%E3%81%8B%E3%82%89%E3%81%A0%E3%82%92%E6%83%B3%E3%81%86%E3%82%AA%E3%83%BC%E3%83%AB%E3%83%95%E3%83%AA%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                      #からだを想うオールフリー
                    </a>{" "}
                    が<br />
                    抽選で500名様に当たる！🎯
                    <br />＼<br />
                    <br />
                    雪印メグミルク×サントリーさんとのコラボ‼{" "}
                    <a href="https://twitter.com/hashtag/%E5%86%85%E8%87%93%E8%84%82%E8%82%AA?src=hash&amp;ref_src=twsrc%5Etfw">
                      #内臓脂肪
                    </a>{" "}
                    対策飲料同士、{" "}
                    <a href="https://twitter.com/hashtag/%E7%9B%B8%E4%BA%92%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%97%E3%81%A6%E3%81%BF%E3%81%9F?src=hash&amp;ref_src=twsrc%5Etfw">
                      #相互レビューしてみた
                    </a>
                    ❗️
                    <br />
                    <br />
                    1⃣
                    <a href="https://twitter.com/megmilk_snow?ref_src=twsrc%5Etfw">
                      @megmilk_snow
                    </a>
                    と{" "}
                    <a href="https://twitter.com/suntory?ref_src=twsrc%5Etfw">
                      @suntory
                    </a>
                    の2つのアカウントをフォロー
                    <br />
                    2⃣この投稿をRT
                    <a href="https://t.co/zmGcNUksyg">
                      https://t.co/zmGcNUksyg
                    </a>
                  </p>
                  &mdash; 【公式】雪印メグミルク株式会社 (@megmilk_snow){" "}
                  <a href="https://twitter.com/megmilk_snow/status/1577086095820554242?ref_src=twsrc%5Etfw">
                    October 4, 2022
                  </a>
                </blockquote>
              </div>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/suntory"
                  target="_blank"
                  rel="noreferrer"
                >
                  サントリー公式アカウント
                </a>
                または
                <a
                  href="https://twitter.com/megmilk_snow"
                  target="_blank"
                  rel="noreferrer"
                >
                  雪印メグミルク公式アカウント
                </a>
                をフォローし、どちらかの該当ツイートをリツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は27日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/suntory"
                  target="_blank"
                  rel="noreferrer"
                >
                  サントリー公式アカウント
                </a>
                または
                <a
                  href="https://twitter.com/megmilk_snow"
                  target="_blank"
                  rel="noreferrer"
                >
                  雪印メグミルク公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 結果は後日DM送付</p>
              <h3>結果通知</h3>
              <p>当選者のみ後日DM送付</p>
            </div>
            <div>
              <h2 id="id4">
                赤城乳業 フォロー＆ハッシュタグ引用リツイート キャンペーン
              </h2>
              <p>
                フォロー＆ハッシュタグ引用リツイートはフォロー＆リツイートキャンペーンに比べると拡散性は下がりますが、ユーザとの関係性を構築するのに適しています。ユーザ自身の「推しフレーバー」をアピールすることができるので、雑談をするような感覚で楽しく参加できます。
              </p>
              <p>
                選択肢が多いと迷ってしまいますが、２種からどちらかを選ぶだけなので気軽です。ユーザの生の声を聞くことができるのでユーザとコミュニケーションを取りたい時に適しているキャンペーン形式です。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼フロリダサンデー推しフレーバー対決／
                  <br />
                  フォロー&amp;引用リツイートキャンペーン開催🎉
                  <br />
                  <br />①
                  <a href="https://twitter.com/akagi_cp?ref_src=twsrc%5Etfw">
                    @akagi_cp
                  </a>
                  <br />
                  アカウントをフォロー
                  <br />
                  ②この投稿を引用リツイートし
                  <a href="https://twitter.com/hashtag/%E3%83%95%E3%83%AD%E3%83%AA%E3%83%80%E3%82%B5%E3%83%B3%E3%83%87%E3%83%BC%E6%8E%A8%E3%81%97%E3%83%95%E3%83%AC%E3%83%BC%E3%83%90%E3%83%BC%E5%AF%BE%E6%B1%BA?src=hash&amp;ref_src=twsrc%5Etfw">
                    #フロリダサンデー推しフレーバー対決
                  </a>{" "}
                  で<br />
                  ストロベリー or ブルーベリーのどちら推しかを投稿
                  <br />
                  ③抽選でフロリダサンデー詰め合わせが当たる！{" "}
                  <a href="https://t.co/Sc4o7ZriHF">
                    pic.twitter.com/Sc4o7ZriHF
                  </a>
                </p>
                &mdash; 赤城乳業【公式】 (@akagi_cp){" "}
                <a href="https://twitter.com/akagi_cp/status/1576014035769643008?ref_src=twsrc%5Etfw">
                  October 1, 2022
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/akagi_cp"
                  target="_blank"
                  rel="noreferrer"
                >
                  赤城乳業公式アカウント
                </a>
                をフォローし、指定のハッシュタグをつけて引用リツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は7日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆ハッシュタグ引用リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/akagi_cp"
                  target="_blank"
                  rel="noreferrer"
                >
                  赤城乳業公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 指定のハッシュタグをつけて引用リツイート</p>
            </div>
            <div>
              <h2 id="id5">
                ロッテ 雪見だいふく フォロー＆ハッシュタグツイート キャンペーン
              </h2>
              <p>
                カンバセーションカードを使用したクイズ形式のフォロー＆ハッシュタグツイートキャンペーンです。リニューアルした自社製品に関するクイズで製品と改良点の２つを上手くPRしています。
              </p>
              <p>
                Twitterにクイズそのものの機能はありませんが、引用リツイートやハッシュタグを利用することでクイズ形式での実施が可能です。ユーザも楽しく参加できますし、このキャンペーンは選択肢も可愛らしくてほっこりしますね。
              </p>
              <p>
                柔らかくて甘い製品の雰囲気ともマッチしています。どんな風に味が変わったのか思わず確かめたくなる内容です。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <a href="https://twitter.com/hashtag/%E9%9B%AA%E8%A6%8B%E3%81%A0%E3%81%84%E3%81%B5%E3%81%8F%E8%AC%8E%E8%A7%A3%E3%81%8D?src=hash&amp;ref_src=twsrc%5Etfw">
                    #雪見だいふく謎解き
                  </a>
                  <br />
                  コクのひみつがわかるかな❓
                  <br />＼<br />
                  <br />
                  バニラアイスが8年ぶりのリニューアル✨
                  <br />
                  〇〇を加えてコクが一新😍
                  <br />
                  〇〇はなんでしょう❓
                  <br />
                  <br />
                  正解者の中から100名様に
                  <a href="https://twitter.com/hashtag/%E9%9B%AA%E8%A6%8B%E3%81%A0%E3%81%84%E3%81%B5%E3%81%8F?src=hash&amp;ref_src=twsrc%5Etfw">
                    #雪見だいふく
                  </a>{" "}
                  5個プレゼント🎁
                  <br />
                  <br />
                  応募
                  <br />①
                  <a href="https://twitter.com/yukimi_lotte?ref_src=twsrc%5Etfw">
                    @yukimi_lotte
                  </a>
                  をフォロー
                  <br />
                  ②解答を選びツイート
                  <br />
                  9/8〆
                  <br />
                  <br />
                  詳細
                  <a href="https://t.co/TXuZ8U3m32">https://t.co/TXuZ8U3m32</a>
                </p>
                &mdash; ロッテ 雪見だいふく (@yukimi_lotte){" "}
                <a href="https://twitter.com/yukimi_lotte/status/1297911542147510275?ref_src=twsrc%5Etfw">
                  August 24, 2020
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/yukimi_lotte"
                  target="_blank"
                  rel="noreferrer"
                >
                  ロッテ 雪見だいふく公式アカウント
                </a>
                をフォローし、指定のハッシュタグをつけてツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は15日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆ハッシュタグツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/yukimi_lotte"
                  target="_blank"
                  rel="noreferrer"
                >
                  ロッテ 雪見だいふく公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 指定のハッシュタグをつけてツイート</p>
            </div>
            <div>
              <h2 id="id6">
                大阪王将 フォロー＆ハッシュタグ引用リツイート キャンペーン
              </h2>
              <p>
                ハッシュタグを利用して大喜利開催、商品は１年分の餃子パスポート皿。しかも餃子の提供は別皿。なんともユニークなキャンペーンです。
              </p>
              <p>
                景品名やお題、景品のビジュアルにモザイク処理など、珍しくて面白い要素がてんこ盛り！
              </p>
              <p>
                他のユーザがどんな投稿をしたのか見るのも楽しいキャンペーンになっています。答えを考えたりハッシュタグをつけたりと手間はかかりますが、熱量の多いユーザやコアなファンの参加や獲得が狙えます。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  &quot;こんな大阪王将は嫌だ！&quot;
                  <br />
                  ㊗創業51周年 大喜利キャンペーン開催🎂
                  <br />
                  <br />
                  ✅大阪王将をフォローする
                  <br />✅{" "}
                  <a href="https://twitter.com/hashtag/%E3%81%93%E3%82%93%E3%81%AA%E5%A4%A7%E9%98%AA%E7%8E%8B%E5%B0%86%E3%81%AF%E5%AB%8C%E3%81%A0?src=hash&amp;ref_src=twsrc%5Etfw">
                    #こんな大阪王将は嫌だ
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E5%A4%A7%E5%96%9C%E5%88%A9?src=hash&amp;ref_src=twsrc%5Etfw">
                    #大喜利
                  </a>{" "}
                  の2つをつけ、
                  <br />
                  お題『こんな大阪王将は嫌だ！』の答えを書いてこの記事を引用RT！
                  <br />
                  <br />
                  抽選で5名様に
                  <br />
                  1年間食いっぱぐれない！
                  <br />
                  大阪王将 餃子年間パスポート皿プレゼント🎁{" "}
                  <a href="https://t.co/moerZbBBX0">
                    pic.twitter.com/moerZbBBX0
                  </a>
                </p>
                &mdash; 大阪王将【公式】🥟 (@osaka_ohsho){" "}
                <a href="https://twitter.com/osaka_ohsho/status/1305657684000010240?ref_src=twsrc%5Etfw">
                  September 15, 2020
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/osaka_ohsho"
                  target="_blank"
                  rel="noreferrer"
                >
                  大阪王将公式アカウント
                </a>
                をフォローし、指定のハッシュタグをつけて引用リツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は13日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆ハッシュタグ引用リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/osaka_ohsho"
                  target="_blank"
                  rel="noreferrer"
                >
                  大阪王将公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 指定のハッシュタグをつけて引用リツイート</p>
              <p>3. 当選者にはDMで連絡</p>
              <h3>結果通知</h3>
              <p>当選者のみ後日DM送付</p>
            </div>
            <div>
              <h2 id="id7">uno(ウーノ) フォロー＆リツイート キャンペーン</h2>
              <p>
                Twitterのフォロー＆リツイートキャンペーンはシンプルでユーザの負担が少なく、参加しやすい人気のキャンペーン形式です。更に、結果がすぐにわかるインスタントウィンキャンペーンはユーザのワクワク感をより盛り上げることができるのでおすすめの手法です。
              </p>
              <p>
                unoでは有名なルパン三世とコラボしたTwitterキャンペーンを開催。わずか２時間の開催で5000RTを達成しています。ルパン達が公式アカウントをジャック、文言や景品名も作品の設定に沿っていて違和感がありません。ただキャラクターとコラボするだけではなく、作品の世界観とキャンペーンの内容がしっかり噛み合っています。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  <a href="https://twitter.com/hashtag/%E3%82%A6%E3%83%BC%E3%83%8E?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ウーノ
                  </a>{" "}
                  アカウントジャックも今日でフィナーレだ！
                  <br />
                  ツイッターの紳士諸君、最後まで俺様につきあってくれよな。
                  <br />
                  <br />
                  今から２時間、ラストは盛大に配りまくるぜ！
                  <br />
                  <br />
                  ▼応募方法
                  <br />①
                  <a href="https://twitter.com/uno_official?ref_src=twsrc%5Etfw">
                    @uno_official
                  </a>{" "}
                  をフォロー
                  <br />
                  ②時間内にこの投稿をRT
                  <br />
                  ※結果はすぐに返信するぜ
                  <a href="https://twitter.com/hashtag/%E3%83%AB%E3%83%91%E3%83%B3%E3%81%8C%E3%81%82%E3%81%92%E3%81%A1%E3%82%83%E3%82%A6%E3%83%BC%E3%83%8E?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ルパンがあげちゃウーノ
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%AB%E3%83%91%E3%83%B3%E4%B8%89%E4%B8%96?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ルパン三世
                  </a>{" "}
                  <a href="https://t.co/UoTgtwuDNu">
                    pic.twitter.com/UoTgtwuDNu
                  </a>
                </p>
                &mdash; uno（ウーノ） (@uno_official){" "}
                <a href="https://twitter.com/uno_official/status/1454010128521314306?ref_src=twsrc%5Etfw">
                  October 29, 2021
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/uno_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  uno（ウーノ）公式アカウント
                </a>
                をフォローし、該当のツイートをリツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は2時間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート（インスタントウィン）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/uno_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  uno（ウーノ）公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 該当のツイートをリツイート</p>
              <p>3. その場で結果を返信</p>
              <h3>結果通知</h3>
              <p>全員に結果を返信</p>
            </div>
            <div>
              <h2 id="id8">
                湖池屋×モルカー Wフォロー＆リツイート キャンペーン
              </h2>
              <p>
                湖池屋はTwitter公式アカウントのフォロワー数が87万人を超える人気アカウントです。定期的に自社製品のプレゼントキャンペーンを行なっており、大勢のファンがいます。
              </p>
              <p>
                コラボ先のモルカーも人気の作品。通常Wフォローキャンペーンはユーザの手間が増えるため参加率が下がりますが、このTwitterキャンペーンは4万RTを越え、通常時のキャンペーンの２倍近く拡散されました。モルカーの「ポテト」というキャラクターが湖池屋の「ポテト」を運んでくるというユニークで可愛らしい企画内容になっています。
              </p>
              <p>
                ユーザの反響に応えるべく、コラボ先に掛け合い当選者数を２倍に変更＆応募期間の延長も実施しています。それだけに留まらず、可愛い箱に傷がつかないように梱包されているという心遣い。コラボ先のキャラクターやファンも大事にする細やかな気遣いに製品以外の魅力も感じます。ファンが多いのも納得ですね。
              </p>
              <p>
                Twitterアカウントの運用においてファンを増やすことはとても重要です。他社のリサーチを行い、良い部分はどんどん吸収していきましょう。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  当選人数を倍に！
                  <br />
                  10名様→【20名様】
                  <br />
                  期間も延長→9/7〆
                  <br />
                  <br />
                  みなさま、本当にたくさんのご応募ありがとうございます🙇‍♀️✨一人でも多くの方にお届けしたくお願いしたところ、モルカー公式
                  <a href="https://twitter.com/molcar_anime?ref_src=twsrc%5Etfw">
                    @molcar_anime
                  </a>
                  さんからも是非！とお返事いただきました😊
                  <br />
                  <br />
                  まだまだ足りないとは思いますが、何卒ご了承ください🙇‍♀️{" "}
                  <a href="https://t.co/lI7orXHAmE">
                    pic.twitter.com/lI7orXHAmE
                  </a>
                </p>
                &mdash; 湖池屋 コイケヤ【公式】 (@koikeya_cp){" "}
                <a href="https://twitter.com/koikeya_cp/status/1564456063566434304?ref_src=twsrc%5Etfw">
                  August 30, 2022
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/koikeya_cp"
                  target="_blank"
                  rel="noreferrer"
                >
                  湖池屋公式アカウント
                </a>
                と
                <a
                  href="https://twitter.com/molcar_anime"
                  target="_blank"
                  rel="noreferrer"
                >
                  PUI PUI モルカー公式アカウント
                </a>
                をフォローし、該当のツイートをリツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は当初の9日間から16日間に延長。</p>
              <h3>キャンペーンタイプ</h3>
              <p>Wフォロー＆リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/koikeya_cp"
                  target="_blank"
                  rel="noreferrer"
                >
                  湖池屋公式アカウント
                </a>
                と
                <a
                  href="https://twitter.com/molcar_anime"
                  target="_blank"
                  rel="noreferrer"
                >
                  PUI PUI モルカー公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 該当のツイートをリツイート</p>
            </div>
            <div>
              <h2 id="id9">
                紀文 アース製薬 MUBLiXトリプルフォロー＆リツイート キャンペーン
              </h2>
              <p>
                「きぶん」を「のりふみ」と読み間違えた個人のツイートに紀文公式が反応して大反響。その読み間違いにアース製薬公式が反応。さらにその読み間違いイジりの流れをMUBLiXがそのままタオルに。バズった記念に３社合同企画で開催されたキャンペーンです。
              </p>
              <p>プレゼントは３社の製品の詰め合わせ。</p>
              <p>
                読み間違いをきっかけにハッシュタグも誕生。多くのユーザを巻き込んで盛り上がりました。TwitterというSNSのノリを理解し上手く活用したキャンペーンです。キャンペーン名のゆるーい感じがまたいいですね。
              </p>
              <p>
                ３アカウントのフォローという手間がありながら1.6万RTを超えています。ユーザとの距離感とTwitterの空気感を正しく掴んだ成果と言えるでしょう。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <a href="https://twitter.com/hashtag/%E3%81%AA%E3%82%93%E3%81%8B%E3%83%90%E3%82%BA%E3%81%A3%E3%81%9F%E3%81%8B%E3%82%89%E8%A8%98%E5%BF%B5%E3%81%AB%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88%E3%81%99%E3%82%8B%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #なんかバズったから記念にプレゼントするキャンペーン
                  </a>{" "}
                  開催🎉
                  <br />＼<br />
                  急の急なんですがなんかバズったんで
                  <br />
                  開催いたします！
                  <br />
                  抽選で1名様に豪華商品全てプレゼント🎁
                  <br />
                  <br />
                  応募方法
                  <br />
                  1️⃣
                  <a href="https://twitter.com/kibun_kitchen?ref_src=twsrc%5Etfw">
                    @kibun_kitchen
                  </a>
                  <br />{" "}
                  <a href="https://twitter.com/mublix_misato?ref_src=twsrc%5Etfw">
                    @mublix_misato
                  </a>{" "}
                  <br />{" "}
                  <a href="https://twitter.com/EarthOfficialJP?ref_src=twsrc%5Etfw">
                    @EarthOfficialJP
                  </a>{" "}
                  をフォロー
                  <br />
                  2️⃣この投稿をRT🔄
                  <br />
                  応募期間2月8日(火)23:59{" "}
                  <a href="https://t.co/lUMyosfM3F">
                    pic.twitter.com/lUMyosfM3F
                  </a>
                </p>
                &mdash; 紀文 【公式】🍢 (@kibun_kitchen){" "}
                <a href="https://twitter.com/kibun_kitchen/status/1489388247058771968?ref_src=twsrc%5Etfw">
                  February 4, 2022
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/kibun_kitchen"
                  target="_blank"
                  rel="noreferrer"
                >
                  紀文公式アカウント
                </a>
                、
                <a
                  href="https://twitter.com/EarthOfficialJP"
                  target="_blank"
                  rel="noreferrer"
                >
                  アース製薬公式アカウント
                </a>
                、
                <a
                  href="https://twitter.com/mublix_misato"
                  target="_blank"
                  rel="noreferrer"
                >
                  MUBLiX公式アカウント
                </a>
                をフォローし、該当のツイートをリツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は5日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>トリプルフォロー＆リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/kibun_kitchen"
                  target="_blank"
                  rel="noreferrer"
                >
                  紀文公式アカウント
                </a>
                、
                <a
                  href="https://twitter.com/EarthOfficialJP"
                  target="_blank"
                  rel="noreferrer"
                >
                  アース製薬公式アカウント
                </a>
                、
                <a
                  href="https://twitter.com/mublix_misato"
                  target="_blank"
                  rel="noreferrer"
                >
                  MUBLiX公式アカウント
                </a>
                をフォローする
              </p>
              <p>2. 該当のツイートをリツイート</p>
            </div>
            <div>
              <h2 id="id10">
                サントリー天然水 フォロー＆リツイート おみくじキャンペーン
              </h2>
              <p>
                年始の開催に相応しいおみくじキャンペーンです。フォロー＆RTと参加のハードルが低く、景品が当たらなくてもおみくじを楽しめるため参加者の満足度が高い内容になっています。
              </p>
              <p>
                リプライに結果がランダムで届くためワクワク感があり、何度も参加したくなります。結果を報告するリプライも多く、ユーザが楽しんでいることがよく伝わってきます。おみくじ結果の画像もオリジナルのものを使用していて、自社ブランドのPR効果も大きいです。季節感とTwitterの特性を活かしたキャンペーンの成功例になります。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  新年あけましておめでとう✨
                  <br />
                  2022年の運勢を占う、
                  <br />
                  おみくじキャンペーン実施中！
                  <br />
                  <br />
                  ■参加方法
                  <br />①
                  <a href="https://twitter.com/suntory_tennen?ref_src=twsrc%5Etfw">
                    @suntory_tennen
                  </a>{" "}
                  をフォロー
                  <br />
                  ②このツイートをRT
                  <br />
                  ③おみくじ結果が届くよ
                  <br />
                  <br />
                  さらに、参加者の中から100名様に
                  <br />
                  天然水2L1ケースが当たる！​
                  <br />
                  是非おみくじを引いてみてね♪
                  <a href="https://twitter.com/hashtag/%E6%96%B0%E6%98%A5%E3%83%9F%E3%82%BA%E3%82%AF%E3%83%9E%E3%81%8A%E3%81%BF%E3%81%8F%E3%81%98?src=hash&amp;ref_src=twsrc%5Etfw">
                    #新春ミズクマおみくじ
                  </a>
                </p>
                &mdash; サントリー天然水 (@suntory_tennen){" "}
                <a href="https://twitter.com/suntory_tennen/status/1478546796142112769?ref_src=twsrc%5Etfw">
                  January 5, 2022
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/suntory_tennen"
                  target="_blank"
                  rel="noreferrer"
                >
                  サントリー天然水公式アカウント
                </a>
                をフォローし、該当のツイートをリツイートします。
              </p>
              <p>当選者には景品がプレゼントされます。</p>
              <p>開催期間は7日間。</p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート（後日抽選）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/suntory_tennen"
                  target="_blank"
                  rel="noreferrer"
                >
                  サントリー天然水公式アカウント
                </a>
                をフォローする
              </p>
              <p>2. 該当のツイートをリツイート</p>
              <p>3. 参加者全員におみくじがリプライで届く</p>
              <p>4. その中から当選者のみにDM</p>
            </div>
            <div>
              <h2 id="id11">まとめ</h2>
              <p>
                Twitterでバズった面白いキャンペーン10選、如何だったでしょうか？
              </p>
              <p>
                Twitterキャンペーンは内容次第で1回のキャンペーンで多くのフォロワーを獲得し、製品や自社の認知度を高めることが可能です。手動では大変な手間がかかりますがツールを導入すれば簡単に開催できます。
              </p>
              <p>
                これからキャンペーンを始めてフォロワーを増やしたい場合は、参加のハードルが低く拡散性の高いフォロー＆リツイート（インスタントウィン）キャンペーンがオススメです。
              </p>
              <p>
                PARKLoTでは業界最安値水準で様々なTwitterキャンペーンの実施が可能です。
              </p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    <a
                      href="/case-study/twitter/instant-win-follow-and-retweet"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆リツイート（インスタントウィン）
                    </a>
                  </li>
                  <li>
                    <a
                      href="/case-study/twitter/twitter-non-instant-win"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆リツイート（後日抽選）
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/hashtag-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      UGC（ハッシュタグ）
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/coupon-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      クーポン配布
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ikejiri" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a href="/blog/coupon-prize" target="_blank" rel="noreferrer">
                    TwitterインスタントウィンのNGプレゼントは？
                    賞品プレゼントとクーポン配布はどっちがいいの？【メリット・デメリット比較】
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/maximize-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの効果を最大化させる方法 6選
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2021-campaign-result"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの成功の秘訣は？PARKLoT公式アカウントのキャンペーン結果を大公開！！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2021-campaign-result-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンを徹底比較！4つの比較テストから導き出した、成功の秘訣とは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2021-campaign-result-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンを徹底比較！4つの比較テストから導き出した、成功の秘訣とは？
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default InterestingTwitterCampaigns
